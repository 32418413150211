/* eslint react/prop-types: 0 */
import React from 'react';
import {compose} from 'redux';
import {connect, useDispatch} from 'react-redux';
import {firestoreConnect, useFirestore, withFirestore} from 'react-redux-firebase'

// Components
import Main from './components/Main';
import Sherpas from './components/Sherpas';
import AllResources from './components/AllResources';
import SherpaForm from './components/SherpaForm';
import AddData from './components/AddData';

import {Route, Switch} from 'react-router' // react-router v4/v5

// Ant Design Components
import {Layout} from 'antd';

// Style
import './App.less';

import {initializeApp} from './store/helper';

const {Content} = Layout;

const App = (props) => {

  const dispatch = useDispatch();
  const {answers, questions, scoreMap, sherpas, resources} = props;

  if (answers && questions && scoreMap && resources) {

    initializeApp(dispatch, answers, questions, scoreMap, sherpas, resources);
  }

  return (
    <Layout>
      <Content>
        <Switch>
          <Route exact path='/' component={Main} />
          <Route path='/form' component={SherpaForm} />
          <Route path='/resources' component={AllResources} />
          <Route path='/sherpas' component={Sherpas} />
        </Switch>
        {/* <AddData /> */}
      </Content>
    </Layout>
  );
}

const enhance = compose(withFirestore,

  firestoreConnect((props) => [
    {
      collection: 'sherpas',
      where: [['disabled', '==', false]],
      storeAs: 'sherpas'
    },
    {
      collection: 'questions'
    },
    {
      collection: 'questionMap'
    },
    {
      collection: 'scoreMap'
    },
    {
      collection: 'groups'
    },
    {
      collection: 'resources'
    }
  ]),
  connect(state => ({
    sherpas: state.firestore.data.sherpas,
    answers: state.answers.answers,
    questions: state.firestore.data.questions,
    scoreMap: state.firestore.data.scoreMap,
    resources: state.firestore.data.resources
  })))

export default enhance(App);
