import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';

// Your web app's Firebase configuration
var firebaseConfig = {
	apiKey: 'AIzaSyAr1bfIcbPeNrIszLtuqIut-WHG31cAHSg',
  authDomain: 'bitcoinsherpa-9c39c.firebaseapp.com',

  databaseURL: 'https://bitcoinsherpa-9c39c-default-rtdb.firebaseio.com',
  projectId: 'bitcoinsherpa-9c39c',
  storageBucket: 'bitcoinsherpa-9c39c.appspot.com',
  messagingSenderId: '32538998890',
  appId: '1:32538998890:web:e96773aa600b3d04def213',
  measurementId: 'G-28C88V4500'
};

// Initialize Firebase
try {
	firebase.initializeApp(firebaseConfig);
  firebase.firestore();
  firebase.analytics();
  firebase.storage();
	console.log('Firebase Initialized');
} catch (err) {
	console.log('Error Initializing Firebase');
}

export default firebase;
