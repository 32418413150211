import React, {useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {
  SearchOutlined
} from '@ant-design/icons';

import _ from 'lodash';

import {Row, Col, Typography, Input, Pagination} from 'antd';

import Sherpa from '../Sherpa';
import HomeLogo from '../HomeLogo';

const {Text} = Typography;

const SHERPA_LIMIT = 10;


const Sherpas = (props) => {
  const {sherpas, filteredSherpas, sherpaSearchValue} = props;
  const [currentPage, setPage] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(SHERPA_LIMIT);
  const dispatch = useDispatch();

  const renderSherpa = (s) => {
    return (
      <Col key={s} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className='sherpaWrapper'>
        <Sherpa sherpa={s} />
      </Col>
    );
  }

  const renderFilteredSherpas = () => {
    return (
      <div className='sherpasWrapper'>
        { renderPagination(filteredSherpas.length)}
        {
          filteredSherpas.map((s, key) => {
            return key > minIndex && key <= maxIndex && renderSherpa(s.sherpa);
          })
        }
        { renderPagination(filteredSherpas.length)}
      </div>
    );
  }

  const renderPagination = (total) => {
    return (
      <Col span={24}>
        <Pagination current={currentPage} showSizeChanger={false} className='sherpaPagination' onChange={(page) => {setPage(page); setMinIndex((page - 1) * SHERPA_LIMIT); setMaxIndex(page * SHERPA_LIMIT);}} total={total} defaultCurrent={currentPage} pageSize={SHERPA_LIMIT} />
      </Col>
    )
  }

  const normalizeString = (str) => {
    return str.replace(/\s+/g, '').toLowerCase();
  }

  const renderSearchedSherpas = () => {

    let normalizedSearch = normalizeString(sherpaSearchValue);

    return Object.keys(sherpas).map((s) => {

      if (normalizeString(sherpas[s].name).indexOf(normalizedSearch) !== -1) return renderSherpa(s);

      sherpas[s].areasOfInterest && sherpas[s].areasOfInterest.map((a) => {
        if (normalizeString(a).indexOf(normalizedSearch) !== -1) return renderSherpa(s);
      });

      sherpas[s].channels && sherpas[s].channels.map((c) => {
        if (normalizeString(c).indexOf(normalizedSearch) !== -1) return renderSherpa(s);
      });

      sherpas[s].social && sherpas[s].social.map((soc) => {
        if (normalizeString(soc).indexOf(normalizeString) !== -1) return renderSherpa(s);
      });
    });


  }

  const renderSherpas = () => {


    if (sherpaSearchValue) {
      return renderSearchedSherpas();
    } else if (filteredSherpas.length > 0) {
      return renderFilteredSherpas();
    }

    let sherpasToRender = Object.keys(sherpas);

    return (
      <div className='sherpasWrapper'>
        {renderPagination(sherpasToRender.length)}
        {
          sherpasToRender.map((s, key) => {
            return minIndex < key && key <= maxIndex && renderSherpa(s);
          })
        }
        {renderPagination(sherpasToRender.length)}
      </div>
    )
  }

  return (
    <Row className='sherpas' id='Sherpas'>
      <HomeLogo />
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className='sherpaHeader'>
        <Text className='white titleText'><span>Find Bitcoin leaders with the same interests</span> &nbsp;<span className='underline'>as you.&nbsp;&nbsp;&nbsp;&nbsp;</span></Text>
      </Col>

      {/* <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} className='sherpaSearch'>
        <Input
          type='text'
          placeholder='Search Your Interests'
          prefix={<SearchOutlined />}
          value={sherpaSearchValue}
          onChange={(e) => dispatch({type: 'SET_SHERPA_SEARCH_VALUE', payload: e.target.value})}
        />
      </Col> */}
      {sherpas && renderSherpas(sherpas)}
    </Row>
  )
}


export default connect(state => ({
  sherpas: state.firestore.data.sherpas,
  filteredSherpas: state.answers.filteredSherpas,
  sherpaSearchValue: state.answers.sherpaSearchValue
}))(Sherpas);