import React from 'react';
import {compose} from 'redux';

import _ from 'lodash';

import {connect, useDispatch} from 'react-redux';
import {firestoreConnect, withFirestore} from 'react-redux-firebase'

import {Row, Select, Typography, Button} from 'antd';
import { RedoOutlined } from '@ant-design/icons';

import {updateAnswers} from '../../store/helper';

const {Text} = Typography;

const Quiz = (props) => {
  const dispatch = useDispatch();

  const {questions, answers, questionMap} = props;

  let questionsToRender = [];

  const makeMultipleSelect = (qid) => {
    const question = questions[qid];
    const questionSegments = question.question.split('%%');

    let defaultValue = [];

    if (answers[qid] && answers[qid].length > 0) defaultValue = answers[qid];

    return (
      <div className='questionContainer' xs={24} sm={24} md={24} key={qid}>
        <Text className='medium black'>{questionSegments[0]}</Text>

        <Select
          className='multiSelectQuestion'
          mode='multiple'
          placeholder='Select All That Apply'
          defaultValue={defaultValue}
          value={answers[qid] && answers[qid]}
          allowClear
          onChange={(answers) => updateAnswers(dispatch, qid, answers, questions)}
        >
          {question.options.map((option, key) => <Select.Option key={option.aid}>{option.text}</Select.Option>)}
        </Select>

        <Text className='medium black'>{questionSegments[1]}</Text>
      </div>
    );
  }

  const makeSingleSelect = (qid) => {
    const question = questions[qid];
    const questionSegments = question.question.split('%%');

    let defaultValue = '';
    if (answers[qid] && answers[qid].length > 0) defaultValue = answers[qid][0];

    return (
      <div className='questionContainer' key={qid}>
        <Text className='medium black'>{questionSegments[0]}</Text>
        <Select
          className={`singleSelectQuestion ${answers[qid] && 'filled'}`}
          placeholder='Select One'
          allowClear
          defaultValue={defaultValue}
          value={answers[qid]}
          onChange={(answer) => {
            dispatch({type: 'UPDATE_ANSWERS', payload: {qid, answers: [answer]}})
          }}
        >
          {question.options.map((option, key) => {
            return (<Select.Option key={key} value={key}>{option.text}</Select.Option>)
          }
          )}
        </Select>
        <Text className='medium black'>{questionSegments[1]}</Text>
      </div>
    )
  }

  const makeCountriesSelect = (qid) => {

    const question = questions[qid];
    const questionSegments = question.question.split('%%');

    let defaultValue = '';
    if (answers[qid] && answers[qid].length > 0) defaultValue = answers[qid][0];

    return (
      <div className='questionContainer' xs={24} sm={24} md={{offset: 4, span: 20}} key={qid}>
        <Text className='medium black'>{questionSegments[0]}</Text>
        <Select
          className={`singleSelectQuestion ${answers[qid] && 'filled'}`}
          placeholder='Select One'
          defaultValue={defaultValue}
          value={answers[qid] && answers[qid]}
          allowClear
          onChange={(answer, option) => dispatch({type: 'UPDATE_ANSWERS', payload: {qid, answers: [option.children.toLowerCase()]}})}
        >
          {question.options.map((option, key) => <Select.Option key={key}>{option}</Select.Option>)}
        </Select>
        <Text className='medium black'>{questionSegments[1]}</Text>
      </div>
    )
  }

  const makeQuestion = (qid) => {
    switch (questions[qid].type) {
      case 'multiple':
        return makeMultipleSelect(qid);
      case 'single':
        return makeSingleSelect(qid);
      case 'countries':
        return makeCountriesSelect(qid);
      default:
        break;
    }
  }

  const pushQuestionAfterCheckingRequirements = (qid) => {

    let proceed = true;

    // .requirements takes the following form
    // {
    //    questionID (qid): [ array of answers from the question ]     
    // }
    // 
    // It is designed this way because the structure of answers is { questionID: [answerID] }
    //  this makes it simple to check the questions requirements against the users answers

    // Iterate through the list of requirements
    Object.keys(questions[qid].requirements).map(req => {

      // If the questions requirements are empty, then the question itself is required.
      //  Any answer to that question will trigger this question to render
      if (questions[qid].requirements[req].length === 0) {
        if (!answers[req]) {
          proceed = false;
        } else if (answers[req].length === 0) {
          proceed = false;
        }
      }

      questions[qid].requirements[req].map(a => {
        if (answers[req] === null || answers[req] === undefined) {
          proceed = false; // If the user has not answered the current question, move on.
        }

        if (answers[req] && answers[req].indexOf(a) === -1){
          proceed = false;
        }
      });

      if (proceed === true) {
        questionsToRender.push({question: questions[qid], qid});
      }
    });

    
  }

  const makeQuestions = (qMap) => {

    if (!questionMap || !questions) return;

    // Iterate through the questionMap (qMap)
    Object.keys(qMap).map((qid) => {

      // If there are no requirements for the current question, then push the question in to render it
      if (Object.keys(questions[qid].requirements).length === 0) {
        questionsToRender.push({question: questions[qid], qid})
      }

      // If the question does have requirements, then pass the responsibility of pushing into the array off to another function
      if (Object.keys(questions[qid].requirements).length >= 1) {
        pushQuestionAfterCheckingRequirements(qid)
      }

      // Recursively call this function
      if (answers[qid] && !_.isEmpty(answers[qid])) {
        makeQuestions(qMap[qid])
      }
    });
  }

  // This populates the questionsToRender array
  questionMap && makeQuestions(questionMap);

  if (questionsToRender.length > 0) questionsToRender.sort((a, b) => a.question.order - b.question.order);

  return (
    <div className='quiz'>
      <div className='questionsContainer'>
        {
          questionsToRender.map((q) => makeQuestion(q.qid))
        }
      </div>

      <div className='quizControls'>
        <Button className='darkBitcoin clearButton' onClick={() => dispatch({type: 'RESET_ANSWERS'})}>Refresh<RedoOutlined /></Button>
      </div>
    </div>
  )
}

const enhance = compose(withFirestore,

  firestoreConnect((props) => [
    {
      collection: 'questions'
    },
    {
      collection: 'questionMap'
    }
  ]),
  connect(state => ({
    questions: state.firestore.data.questions,
    answers: state.answers.answers,
    questionMap: state.firestore.data.questionMap
  })))

export default enhance(Quiz);