import React, {useState} from 'react';
import {useFirestore} from 'react-redux-firebase';

import _ from 'lodash';

import {Row, Col, Tag, Typography, Tooltip, Image, Input, Select, Upload, Button} from 'antd';

import {useFirebase} from 'react-redux-firebase'

const {Text} = Typography;

const resourceLevels = [
  'beginner',
  'intermediate',
  'advanced'
];

const resourceTypes = [
  'softwareWallet',
  'hardwareWallet',
  'exchanges',
  'books',
  'podcasts',
  'leaders',
  'guides',
  'interviews',
]

const channels = [
  'Podcast',
  'Twitter',
  'Facebook',
  'Book',
  'YouTube',
  'Newsletter',
  'Blog',
  'Instagram',
  'TikTok',
  'Clubhouse',
  'Website',
  'Telegram',
  'Reddit',
  'WhatsApp'
]

const areasOfInterest = [
  'Lightning Network',
  'Satoshi Nakamoto',
  'Bitcoin for Business',
  'Economics',
  'Bitcoin Security',
  'Bitcoin Privacy',
  'Money',
  'Bitcoin for Social Media',
  'Bitcoin for the Unbanked',
  'Bitcoin Mining',
  'Bitcoin Wallets',
  'Bitcoin Trading',
  'Bitcoin MultiSig',
  'Bitcoin Investing',
  'Bitcoin Development',
  'Bitcoin Charity',
  'Bitcoin Art',
  'Bitcoin Futures',
  'Bitcoin Venture Capital',
  'Bitcoin Angel Investing',
  'Bitcoin Entrepreneurship',
  'Bitcoin Consulting',
  'Bitcoin Education',
  'Bitcoin Margins',
  'Earning Bitcoin',
  'Bitcoin Spending',
  'Bitcoin Collateralization',
  'Bitcoin Accounting',
  'Bitcoin Taxes',
  'Bitcoin in Real Estate',
  'Monetary History',
  'CipherPunk',
  'Cryptography',
  'Buying Bitcoin'
];

const social = [
  'Feminist',
  'Progressivist',
  'Property Rights',
  'Populist',
  'Environmental Consciousness',
  'Fundamentalist',
  'Communist / Socialist',
  'Anarchist',
  'BLM',
  'Capitalist (Free Markets)',
  'Anti-Capitalist',
  'Gun Rights (2nd Ammendment)',
  'Libertarianist',
  'Anti-Trump',
  'Pro-Trump',
  'Anti-War',
  'Anti-Taxes',
  'Inclusivity',
  'Vegan',
  'Psychedelic Proponent',
  'Free Speech',
  'Anti Covid Lockdown',
  'Anti-Masker',
  'Pro Covid Lockdown'
]

const nationality = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands"
]

const STATUS = {
  'DEFAULT': 'DEFAULT',
  'SUBMITTED': 'SUBMITTED',
  'SUCCESS': 'SUCCESS',
  'FAILURE': 'FAILURE'
};

const SherpaForm = (props) => {

  const [formInput, setFormInput] = useState({});
  const firebase = useFirebase();
  const firestore = useFirestore();
  const [formStatus, setFormStatus] = useState(STATUS.DEFAULT);

  const [resourceFormInput, setResourceFormInput] = useState({});
  const [resourceFormStatus, setResourceFormStatus] = useState(STATUS.DEFAULT);

  const setInput = (key, value) => {
    let newValue = _.clone(formInput);
    newValue[key] = value;
    setFormInput(newValue);
  }

  const setResourceInput = (key, value) => {
    let newValue = _.clone(resourceFormInput);
    newValue[key] = value;
    setResourceFormInput(newValue);
  }

  return (
    <Row className='forms'>
      <Row className='sherpaForm'>
        <Text className='formTitle'>NEW SHERPA FORM</Text>
        <label>Name</label>
        <Input className='formItem' value={formInput.name} onChange={(e) => setInput('name', e.target.value)} />
        <label>Description</label>
        <Input className='formItem' value={formInput.description} onChange={(e) => setInput('description', e.target.value)} />

        <label>Gender</label>
        <Select className='formItem' value={formInput.gender} onChange={(e) => setInput('gender', e)}>
          <Select.Option value='Male'>Male</Select.Option>
          <Select.Option value='Female'>Female</Select.Option>
          <Select.Option value='Other'>Other</Select.Option>
        </Select>

        <label>Age</label>
        <Input className='formItem' value={formInput.age} type='number' onChange={(e) => setInput('age', e.target.value)} />

        <label>Social Media Channels</label>
        <Select
          mode='multiple'
          className='formItem'
          value={formInput.channels}
          onChange={(e) => setInput('channels', e)}
        >
          {
            channels.map((opt, key) => {
              return (<Select.Option key={key} value={opt}>{opt}</Select.Option>)
            })
          }
        </Select>

        <label>WhatsApp URL</label>
        <Input className='formItem' value={formInput.whatsAppURL} onChange={(e) => setInput('whatsAppURL', e.target.value)} />

        <label>Reddit URL</label>
        <Input className='formItem' value={formInput.redditURL} onChange={(e) => setInput('redditURL', e.target.value)} />

        <label>Telegram URL</label>
        <Input className='formItem' value={formInput.telegramURL} onChange={(e) => setInput('telegramURL', e.target.value)} />

        <label>Newsletter URL</label>
        <Input className='formItem' value={formInput.newsletterURL} onChange={(e) => setInput('newsletterURL', e.target.value)} />

        <label>Website URL</label>
        <Input className='formItem' value={formInput.websiteURL} onChange={(e) => setInput('websiteURL', e.target.value)} />

        <label>Book URL</label>
        <Input className='formItem' value={formInput.bookURL} onChange={(e) => setInput('bookURL', e.target.value)} />

        <label>YouTube URL</label>
        <Input className='formItem' value={formInput.youtubeURL} onChange={(e) => setInput('youtubeURL', e.target.value)} />

        <label>Blog URL</label>
        <Input className='formItem' value={formInput.blogURL} onChange={(e) => setInput('blogURL', e.target.value)} />

        <label>Instagram URL</label>
        <Input className='formItem' value={formInput.instagramURL} onChange={(e) => setInput('instagramURL', e.target.value)} />

        <label>Twitter URL</label>
        <Input className='formItem' value={formInput.twitterURL} onChange={(e) => setInput('twitterURL', e.target.value)} />

        <label>Podcast URL</label>
        <Input className='formItem' value={formInput.podcastURL} onChange={(e) => setInput('podcastURL', e.target.value)} />

        <label>Facebook URL</label>
        <Input className='formItem' value={formInput.facebookURL} onChange={(e) => setInput('facebookURL', e.target.value)} />

        <label>LinkedIn URL</label>
        <Input className='formItem' value={formInput.linkedInURL} onChange={(e) => setInput('linkedInURL', e.target.value)} />


        <label>Areas of Interest</label>
        <Select
          mode='multiple'
          className='formItem'
          value={formInput.areasOfInterest}
          onChange={(e) => setInput('areasOfInterest', e)}
        >
          {
            areasOfInterest.map((opt, key) => {
              return (<Select.Option key={key} value={opt}>{opt}</Select.Option>)
            })
          }
        </Select>

        <label>Social / Political Standpoint</label>
        <Select
          mode='multiple'
          className='formItem'
          value={formInput.social}
          onChange={(e) => setInput('social', e)}
        >
          {
            social.map((opt, key) => {
              return (<Select.Option key={key} value={opt}>{opt}</Select.Option>)
            })
          }
        </Select>

        <label>Nationalities</label>
        <Select
          mode='multiple'
          className='formItem'
          value={formInput.nationality}
          onChange={(e) => setInput('nationality', e)}
        >
          {
            nationality.map((opt, key) => {
              return (<Select.Option key={key} value={opt}>{opt}</Select.Option>)
            })
          }
        </Select>



        <label>PhotoGraph</label>
        <Upload className='formItem' action={(file) => {
          let fileName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
          firebase.storage().ref(fileName).put(file).then(async (snapshot) => {
            let downloadURL = await firebase.storage().ref(fileName).getDownloadURL();
            setInput('img', downloadURL);
          });

        }}><Button>Click to Upload</Button></Upload>

        <Button
          type='primary'
          disabled={!formInput.img}
          onClick={async () => {
            setFormStatus(STATUS.SUBMITTED);
            let result = await firestore.collection('sherpas').add(formInput);
            result.id ? setFormStatus(STATUS.SUCCESS) : setFormStatus(STATUS.FAILURE);
          }}>Submit</Button>

        <Text>{formStatus === STATUS.SUCCESS ? 'SUCCESS' : formStatus === 'FAILURE' ? 'FAILURE' : ''}</Text>

      </Row>

      <Row className='resourceForm'>
        <Text className='formTitle'>NEW RESOURCE FORM</Text>

        <label>Resource Type</label>
        <Select
          className='formItem'
          onChange={(type) => setResourceInput('type', type)}
          value={resourceFormInput.type}
        >
          {
            resourceTypes.map((type) => {
              return <Select.Option value={type}>{type}</Select.Option>
            })
          }
        </Select>

        <label>Resource Name</label>
        <Input
          type='text'
          onChange={(e) => setResourceInput('name', e.target.value)}
          value={resourceFormInput.name}
        />

        <label>Resource Link</label>
        <Input
          type='text'
          onChange={(e) => setResourceInput('defaultLink', e.target.value)}
          value={resourceFormInput.defaultLink}
        />

        <label>Resource Levels</label>
        <Select
          mode='multiple'
          className='formItem'
          onChange={(levels) => setResourceInput('levels', levels)}
          value={resourceFormInput.levels}
        >
          {
            resourceLevels.map((levels) => {
              return <Select.Option value={levels}>{levels}</Select.Option>
            })
          }
        </Select>

        <label>Resource Countries</label>
        <Select
          mode='multiple'
          className='formItem'
          onChange={(countries) => setResourceInput('countries', countries)}
          value={resourceFormInput.countries}
        >
          {
            nationality.map(n => {
              return <Select.Option value={n}>{n}</Select.Option>
            })
          }
        </Select>


        <label>PhotoGraph</label>
        <Upload className='formItem' action={(file) => {
          let fileName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
          firebase.storage().ref(fileName).put(file).then(async (snapshot) => {
            let downloadURL = await firebase.storage().ref(fileName).getDownloadURL();
            setResourceInput('img', downloadURL);
          });

        }}><Button>Click to Upload</Button></Upload>

        <Button
          type='primary'
          disabled={!resourceFormInput.img || !resourceFormInput.name || !resourceFormInput.type}
          onClick={async () => {
            const { type } = resourceFormInput;

            setResourceFormStatus(STATUS.SUBMITTED);
            let resourceUpdate = {};
            // Convert the name to camelCase
            let resourceName = resourceFormInput.name.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
              return index === 0 ? word.toLowerCase() : word.toUpperCase();
            }).replace(/\s+/g, '');

            delete resourceFormInput.type;

            resourceUpdate[resourceName] = resourceFormInput;

            let result = await firestore.doc(`resources/${type}`).set(resourceUpdate, { merge: true });
            result.id ? setResourceFormStatus(STATUS.SUCCESS) : setResourceFormStatus(STATUS.FAILURE);
          }}>Submit</Button>
      </Row>
    </Row>
  )
}


export default SherpaForm;