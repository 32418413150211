
import _ from 'lodash';

export const updateAnswers = (dispatch, qid, answers, questions) => {
  dispatch({type: 'UPDATE_ANSWERS', payload: {qid, answers}})
}

const filterResources = (dispatch, score, scoreMap, resources) => {
  if (resources === null || resources === undefined) return {};

  let filteredResources = {};

  Object.keys(score).map((s) => {
    Object.keys(resources).map((resource) => {
      Object.keys(resources[resource]).map((r) => {
        if (resources[resource][r].levels.indexOf(s) !== -1) {
          if (_.isEmpty(filteredResources[resource])) filteredResources[resource] = {};
          filteredResources[resource][r] = resources[resource][r];
        }
      })
    });
  });
  
  dispatch({ type: 'SET_FILTERED_RESOURCES', payload: { filteredResources }});    
}

const filterSherpas = (dispatch, score, scoreMap, sherpas) => {

  if (sherpas === null || sherpas === undefined) return [];


  let filteredSherpas = [];

  // Iterate through each Sherpa in order to generate them a recommendation rating
  Object.keys(sherpas).map((sherpa) => {

    let sherpaRecommendationRating = 0;

    // Iterate through each of the items in the score
    Object.keys(score).map((s) => {

      // Select the item in the scoreMap that corresponds to the current score item 's'
      if (scoreMap[s] !== undefined) {
        let selectedScoreMap = scoreMap[s];
        // Iterate through the triggers in the selectedScoreMap to see if the current sherpa has a match for the one of the triggers
        selectedScoreMap.triggers.map((trigger) => {
          // Check to see if the score tag is present within the sherpas area of interest
          //  if the trigger is in the areas of interest array, then we add the weight of the trigger to the recommendationRating 

          if (sherpas[sherpa].areasOfInterest !== undefined) {
            if (sherpas[sherpa].areasOfInterest.indexOf(trigger) !== -1) {
              sherpaRecommendationRating += selectedScoreMap.weight;
            }
          }
        });
      }
    });

    if (sherpaRecommendationRating > 0) {
      filteredSherpas.push({
        recommendationRating: sherpaRecommendationRating,
        sherpa
      });
    }
  });

  filteredSherpas.sort((a, b) => b.recommendationRating - a.recommendationRating);

  const SHERPA_LIMIT = 8;
  let sherpasToRender = [];

  while (sherpasToRender.length < SHERPA_LIMIT) {
    let randomSherpa = Math.floor(Math.random() * Object.keys(sherpas).length);
    if (sherpasToRender.indexOf(randomSherpa) === -1 && Object.keys(sherpas)[randomSherpa].disabled !== true) sherpasToRender.push(randomSherpa);
  }
  
  dispatch({ type: 'SET_FILTERED_SHERPAS', payload: {filteredSherpas, sherpasToRender }});    

  return filteredSherpas;
}

const updateScore = (dispatch, questions, answers) => {
  let score = {};
  Object.keys(answers).map((qid) => {
    answers[qid].map((answer) => {
      if (questions[qid].type === 'countries') return;
      questions[qid].options[answer].score.map((scoreItem) => {

        if (_.isEmpty(score[scoreItem])) score[scoreItem] = 0;
        score[scoreItem] += 1;
      });
    });
  });

  dispatch({ type: 'SET_SCORE', payload: score });
  return score;
}

export const initializeApp = (dispatch, answers, questions, scoreMap, sherpas, resources) => {
  const score = updateScore(dispatch, questions, answers);

  filterResources(dispatch, score, scoreMap, resources);
  filterSherpas(dispatch, score, scoreMap, sherpas);
}