import React, {useState} from 'react';
import {connect} from 'react-redux';

import {Link} from 'react-router-dom';

import _ from 'lodash';

import {Row, Col, Typography, Select, Checkbox, Image} from 'antd';
import {CaretDownOutlined} from '@ant-design/icons';

import Bitcoin from '../Bitcoin';
import HomeLogo from '../../assets/homeLogo.png';
import SherpaTile from '../SherpaTile';

const {Text} = Typography;

const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands"
];

const levels = [
  'beginner',
  'intermediate',
  'advanced'
];

const AllResources = (props) => {

  const {resources, sherpas, sherpasToRender} = props;

  const [selectedCountry, setCountry] = useState(null);
  const [selectedLevel, setLevel] = useState('beginner');

  const [exchangesChecked, setExchanges] = useState(false);
  const [walletsChecked, setWallets] = useState(false);
  const [podcastsChecked, setPodcasts] = useState(false);
  const [leadersChecked, setLeaders] = useState(false);
  const [videosChecked, setVideos] = useState(false);
  const [booksChecked, setBooks] = useState(false);
  const [interviewsChecked, setInterviews] = useState(false);
  const [strategiesChecked, setStrategies] = useState(false);
  const [guidesChecked, setGuides] = useState(false);

  const [siderCollapsed, setCollapse] = useState(false);

  const renderResources = () => {

    return Object.keys(resources).map((resource, key) => {

      let render;

      if (!exchangesChecked && !walletsChecked && !podcastsChecked && !leadersChecked && !videosChecked && !booksChecked && !interviewsChecked && !strategiesChecked && !guidesChecked) render = true;
      else render = false;

      if (exchangesChecked && resource === 'exchanges') render = true;
      if (walletsChecked && (resource === 'softwareWallets' || resource === 'hardwareWallets')) render = true;
      if (podcastsChecked && resource === 'podcasts') render = true;
      if (leadersChecked && resource === 'leaders') render = true;
      if (videosChecked && resource === 'videos') render = true;
      if (booksChecked && resource === 'books') render = true;
      if (interviewsChecked && resource === 'interviews') render = true;
      if (strategiesChecked && resource === 'strategies') render = true;
      if (guidesChecked && resource === 'guides') render = true;

      return render === true && (
        <Row className='category' key={key}>
          <Text className='categoryTitle'><span className='underline black'>{resource.replace(/([a-z])([A-Z])/g, '$1 $2')}&nbsp;&nbsp;&nbsp;&nbsp;</span></Text>
          {
            Object.keys(resources[resource]).map((r, rKey) => renderResource(resources[resource][r], rKey))
          }
        </Row>
      )
    }
    )
  }

  const renderResource = (resource, key) => {

    let render = true;

    if (selectedLevel === 'beginner' && resource.levels.indexOf('beginner') === -1) render = false;
    if (selectedLevel === 'intermediate' && resource.levels.indexOf('intermediate') === -1) render = false;
    if (selectedLevel === 'advanced' && resource.levels.indexOf('advanced') === -1) render = false;

    return render === true && (<Col span={8} key={key} className='resource'>
      <a href={resource.defaultLink} target='_blank' rel='noopener noreferrer'>
        <div className='imgWrapper'>
          <img src={resource.img} />
        </div>
        <Text className='resourceTitle'>{resource.name}</Text>
      </a>
    </Col>);
  }

  const renderLeaders = () => {
    const sherpasArray = sherpas ? Object.keys(sherpas) : [];

    return (
      <Row className='category'>
        <Text className='categoryTitle'><span className='underline black'>Leaders&nbsp;&nbsp;&nbsp;&nbsp;</span></Text>

        { sherpas && sherpasToRender && sherpasToRender.length > 0 ?
          sherpasToRender.map((s) => <SherpaTile s={sherpasArray[s]}/>)
          : _.times(12, s => <SherpaTile s={sherpasArray[s]} />)
        }
      </Row>
    )
  }

  return (
    <Row className='allResources' id='allResources'>

      <Link to='/'><img className='homeLogo' src={HomeLogo} alt='Bitcoin Sherpa Home Logo' /></Link>

      <Bitcoin className='position1' />
      <Bitcoin className='position2' />
      <Bitcoin className='position3' />

      <Col span={24} className='resourcesHeader'><Text className='titleText resourcesTitle'><span>All Bitcoin&nbsp;</span><span className='underline'>Resources&nbsp;&nbsp;&nbsp;&nbsp;</span></Text></Col>

      <Col xs={24} sm={24} md={6} className='filters'>

        <Text className='filterTitle black'><span className='black'>Filter&nbsp;</span><span className='black underline'>resources&nbsp;&nbsp;&nbsp;&nbsp;</span></Text>

        <Select suffixIcon={<CaretDownOutlined />} className='filterSelect countries' placeholder='Select Country' onChange={(country) => setCountry(country)}>{countries.map((c, key) => <Select.Option key={key} value={c}>{c}</Select.Option>)}</Select>
        <Select suffixIcon={<CaretDownOutlined />} defaultValue={selectedLevel} className='filterSelect levels' placeholder='Select Your Level' onChange={(level) => setLevel(level)}>{levels.map((l, key) => <Select.Option key={key} value={l}>{l}</Select.Option>)}</Select>

        <Checkbox className='filterCheckbox' onChange={() => setExchanges(!exchangesChecked)} checked={exchangesChecked}>Exchanges</Checkbox>
        <Checkbox className='filterCheckbox' onChange={() => setWallets(!walletsChecked)} checked={walletsChecked}>Wallets</Checkbox>
        <Checkbox className='filterCheckbox' onChange={() => setPodcasts(!podcastsChecked)} checked={podcastsChecked}>Podcasts</Checkbox>
        <Checkbox className='filterCheckbox' onChange={() => setLeaders(!leadersChecked)} checked={leadersChecked}>Bitcoin Leaders</Checkbox>
        <Checkbox className='filterCheckbox' onChange={() => setVideos(!videosChecked)} checked={videosChecked}>Videos</Checkbox>
        <Checkbox className='filterCheckbox' onChange={() => setBooks(!booksChecked)} checked={booksChecked}>Books</Checkbox>
        <Checkbox className='filterCheckbox' onChange={() => setInterviews(!interviewsChecked)} checked={interviewsChecked}>Interviews</Checkbox>
        <Checkbox className='filterCheckbox' onChange={() => setStrategies(!strategiesChecked)} checked={strategiesChecked}>Strategies</Checkbox>
        <Checkbox className='filterCheckbox' onChange={() => setGuides(!guidesChecked)} checked={guidesChecked}>Guides</Checkbox>
      </Col>


      <Col xs={24} sm={24} md={18} className='resources'>

        <Text className='resourcesTitle mobileHide'>All Bitcoin Resources</Text>

        {resources && renderResources()}
        {sherpas && renderLeaders()}
      </Col>
    </Row>
  )
}


export default connect(state => ({
  resources: state.firestore.data.resources,
  sherpas: state.firestore.data.sherpas,
  sherpasToRender: state.answers.sherpasToRender
}))(AllResources);