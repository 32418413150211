import _ from 'lodash';

let answers = JSON.parse(localStorage.getItem('answers')) || {};
let interests = JSON.parse(localStorage.getItem('interests')) || [];

const initialState = {
  answers,
  interests,
  filteredSherpas: [],
  filteredResources: {},
  score: {},
  sherpaSearchValue: ''
};

export default function (state = initialState, action) {
  switch (action.type) {

    case 'SET_SHERPA_SEARCH_VALUE': {
      return {
        ...state, 
        sherpaSearchValue: action.payload
      }
    }

    case 'UPDATE_ANSWERS': {
      let answers = _.clone(state.answers);
      
      if (action.payload.answers.length === 1 && (action.payload.answers[0] === null || action.payload.answers[0] === undefined)) {
        answers[action.payload.qid] = [];
      } else {
        answers[action.payload.qid] = action.payload.answers;
      }

      localStorage.setItem('answers', JSON.stringify(answers));

      return  {
        ...state,
        answers
      };
    }

    case 'RESET_ANSWERS': {
      localStorage.setItem('answers', JSON.stringify({}));
      return {
        ...state,
        answers: {}
      };
    }

    case 'SET_FILTERED_RESOURCES': {
      return {
        ...state,
        filteredResources: action.payload.filteredResources
      }
    }

    case 'SET_FILTERED_SHERPAS': {
      return {
        ...state,
        filteredSherpas: action.payload.filteredSherpas,
        sherpasToRender: action.payload.sherpasToRender
      }
    }

    case 'SET_SCORE': {
      return {
        ...state,
        score: action.payload
      }
    }

    case 'UPDATE_INTERESTS': {

      let interests = state.interests.slice();

      if (interests.indexOf(action.interest) === -1) {
        interests.push(action.interest);
      } else {
        interests = interests.filter(i => i !== action.interest);
      }

      localStorage.setItem('interests', JSON.stringify(interests));

      return {
        ...state, 
        interests
      }
    }

    default:
      return state;
  }
};