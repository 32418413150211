
import React from 'react';
import {Row, Col, Button, Typography} from 'antd';

import Quiz from '../Quiz';
import SherpaCollageMobile from './SherpaCollageMobile';
import ResourceCollage from './ResourceCollage';

import { DownOutlined } from '@ant-design/icons';


const {Text} = Typography;

export default function Main() {

  return (
    <Row className='main'>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className='header'>
        <Text className='siteTitle normal'>
          <span className='uppercase bold title'>Welcome to <span className='darkBitcoin'>Bitcoin Sherpa</span></span><br /><span className='subTitle'>A collection of Bitcoin Leaders and Resources for your <span className='underline'>Bitcoin journey.&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
          <Button className='getStartedButton darkBitcoin'><a href='#getStarted'>Get Started</a>&nbsp;&nbsp;<DownOutlined /></Button>
        </Text>
      </Col>



      <Col id='getStarted' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className='quizWrapper'>
        <Text className='black quizHeader'><span className='black'>Complete this paragraph to find the right resources&nbsp;</span><span className='underline black'>for you.&nbsp;&nbsp;&nbsp;&nbsp;</span></Text>
        <Quiz />
      </Col>

      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className='sherpaCollageWrapper'>
        <SherpaCollageMobile />
        <ResourceCollage />
      </Col>
    </Row>
  )
}