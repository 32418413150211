import React from 'react';
import BTC from '../../assets/BC_Logo_.png';

const Bitcoin = (props) => {
  return (
    <div className={`bitcoinWrapper ${props.className}`}>
      <img className='bitcoinImage' src={BTC} alt='Bitcoin' />
    </div>
  );
}

export default Bitcoin;