import React from 'react';
import {Row, Col, Typography, Button, Image} from 'antd';
import {ArrowRightOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {firestoreConnect, withFirestore} from 'react-redux-firebase'
import _ from 'lodash';

import Book from '../../../assets/sincerely-media-nGrfKmtwv24-unsplash.jpeg';
import Exchanges from '../../../assets/austin-distel-DfjJMVhwH_8-unsplash.jpeg';
import HardwareWallets from '../../../assets/simon-MH5oMxDxjpw-unsplash.jpeg';
import SoftwareWallets from '../../../assets/sajad-nori-eKajbzp-4XU-unsplash.jpeg';
import Podcasts from '../../../assets/sam-rios-BZ6KTN4gem4-unsplash.jpeg';

const {Text} = Typography;

const defaultResources = [
  {
    img: Book,
    text: 'Books'
  },
  {
    img: Exchanges,
    text: 'Exchanges'
  },
  {
    img: HardwareWallets,
    text: 'Hardware Wallets'
  },
  {
    img: SoftwareWallets,
    text: 'Software Wallets'
  },
  {
    img: Podcasts,
    text: 'Podcasts'
  }
]

const ResourceCollage = (props) => {

  const {resources, filteredResources} = props;

  const renderResources = () => {
    return (
      <div className='resources'>
        {
          defaultResources.map((r) => {
            return (
              <div className='resource'>
                <Image className='resourceImage' src={r.img} />
                <Text className='black resourceText'>{r.text}</Text>
              </div>
            )

          })
        }
      </div>
    );
  }

  const renderFilteredResources = () => {

    if (_.isEmpty(resources) || resources === undefined) return;

    let resourcesToRender = _.isEmpty(filteredResources) ? resources : filteredResources;

    return Object.keys(resourcesToRender).map((r, key) => {
      return (
        <Col span={6} className='resourceBlock' key={key}>
          <a className='resource' href={resourcesToRender[r][Object.keys(resourcesToRender[r])[0]].defaultLink}>
            <div className='resourceImage'><img src={resourcesToRender[r][Object.keys(resourcesToRender[r])[0]].img} alt={resourcesToRender[r][Object.keys(resourcesToRender[r])[0]].name} /></div>
            {/* <Text className='resourceName'>{resources[r][Object.keys(resources[r])[0]].name}</Text> */}
            <Text className='resourceTitle'>{r.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}</Text>
          </a>
        </Col>
      )
    })
  }


  return (
    <Row className='resourceCollage'>
      <Col span={24}><Text className='resourceTitle black'>Bitcoin Sherpa <span className='black underline'>Resources&nbsp;&nbsp;&nbsp;&nbsp;</span> </Text></Col>
      <div className='resourcesWrapper'>{renderResources()}</div>
      <Col span={24} className='seeAllResources'><Button className='darkBitcoin'><Link to='/resources'> View All Resources<ArrowRightOutlined /></Link></Button></Col>
    </Row >
  );
}

const enhance = compose(withFirestore,

  firestoreConnect((props) => [
  ]),
  connect(state => ({
    filteredSherpas: state.answers.filteredSherpas,
    filteredResources: state.answers.filteredResources,
    resources: state.firestore.data.resources
  }))
)

export default enhance(ResourceCollage);