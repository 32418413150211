import { combineReducers } from 'redux';
import { firebaseStateReducer as firebase } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import AnswerReducer from './AnswerReducer';
import {connectRouter} from 'connected-react-router';

const rootReducer = (history) => combineReducers({
  firebase,
  firestore: firestoreReducer,
  answers: AnswerReducer,
  router: connectRouter(history)
});

export default rootReducer;
