import React, {useState} from 'react';
import {Row, Col, Typography, Modal, Skeleton, Image, Button} from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {firestoreConnect, withFirestore} from 'react-redux-firebase'
import Sherpa from '../../Sherpa';
import _ from 'lodash';
import SherpaTile from '../../SherpaTile';

const {Text} = Typography;

const SherpaCollageMobile = (props) => {

  const {sherpas, sherpasToRender, filteredSherpas} = props;

  const sherpasArray = sherpas ? Object.keys(sherpas) : [];

  const renderFilteredSherpas = (showSecondHalf) => {

    let count = 0;
    return filteredSherpas.map((sherpa, key) => {

      if (showSecondHalf) {
      return (key >= filteredSherpas.length / 2 && count++ < 4) && (
        <SherpaTile s={sherpa.sherpa} key={sherpa.sherpa} />
      )
      } else {
        return (key < filteredSherpas.length / 2 && count++ < 4) && (
          <SherpaTile s={sherpa.sherpa} key={sherpa.sherpa} />
        )
      }
    })
  }

  const renderSherpas = (showSecondHalf) => {
    return sherpasToRender.map((sherpa, key) => {

      if (showSecondHalf) {
        return key >= sherpasToRender.length / 2 && (
          <SherpaTile s={sherpasArray[sherpa]} key={sherpasArray[sherpa]} />
        )
      } else {
        return key < sherpasToRender.length / 2 && (
          <SherpaTile s={sherpasArray[sherpa]} key={sherpasArray[sherpa]} />
        )
      }
    })
  }

  return (
    <Row className='sherpaCollageMobile'>
      <Col span={24} className='showAllSherpas'>
        <Text className='black titleText'>Follow Your&nbsp;<span className='underline black'>Sherpas&nbsp;&nbsp;&nbsp;&nbsp;</span></Text>
      </Col>
      <Col span={24} className='collageWrapper'>
        {
          (filteredSherpas && filteredSherpas.length > 0) ?
            renderFilteredSherpas(false) :
            (sherpasToRender && sherpasToRender.length > 0) ?
              renderSherpas(false) :
              _.times(4, () => <div className='imageSkeletons'><Skeleton.Image active /><Skeleton active paragraph={{rows: 0}} /></div>)
        }
      </Col>
      <Col span={24} className='collageWrapper'>
        {
          (filteredSherpas && filteredSherpas.length > 0) ?
            renderFilteredSherpas(true) :
            (sherpasToRender && sherpasToRender.length > 0) ?
              renderSherpas(true) :
              _.times(4, () => <div className='imageSkeletons'><Skeleton.Image active /><Skeleton active paragraph={{rows: 0}} /></div>)
        }
      </Col>
      
      <Col span={24} className='showAllSherpas'>
        <Button className='darkBitcoin'><Link to='/sherpas'> View All Sherpas </Link><ArrowRightOutlined /></Button>
      </Col>
    </Row >
  );
};

const enhance = compose(withFirestore,

  firestoreConnect((props) => []),
  connect(state => ({
    sherpas: state.firestore.data.sherpas,
    sherpasToRender: state.answers.sherpasToRender,
    filteredSherpas: state.answers.filteredSherpas
  }))
)

export default enhance(SherpaCollageMobile);